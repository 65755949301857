import axios from 'axios';

export const fetch = async (baseURL, obj) => {
    try {
        const res = await axios.post(baseURL, obj);
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        console.log('fetching done');
    }
};
